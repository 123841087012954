
import { mapGetters, mapActions } from "vuex";
import i18n from "@/vueI18n";
// import {i18n} from '@/i18n';
// import DeletePrescriptionDialog from '@/modules/services/components/delete-prescription-dialog.vue';
import DeleteEdit from "@/modules/services/components/delete-edit.vue";
import firebase from "firebase/app";
import "firebase/firestore";
import moment from 'moment'
// import DeletePrescriptionComment from '@/modules/prescriptions/components/delete-prescription-comment.vue';
export default {
  data() {
    return {
      drugDealURL: "https://drug-deal-web-app.web.app/prescriptions/",
      deletePrescription: false,
      deleteComment: false,
      editPrescription: false,
      // currentUser:null,
      editCommentId: "",
      comment: "",
      // user: null,
      // comments:[],
      commentsWithCreators: false,
      slicedComment: [],
      allComments: true,
      prescription: null,
      // viewed: false,
      id: this.$route.params.id,
      commentsToUpdate: [],
      commentsLength: 2,
      // test:[],
      test: new Map(),
      array: [],
      commentsObject: {},
      updatedComment: "",
      typing: false,
      usersTyping: [],
      timeOutVar: null,
      recordId: undefined,
      recordForComment: {},
      datePresentation: null,
      rowsToBeDisplayed: [],
      openImageDialog: false,
      newCommentCreated: false
    };
  },
  watch: {    
    $route(to, from) {
      // debugger
      if(to.params.id && (to.params.id != from.params.id)){
        this.findRecord(to.params.id)
      }
      // if(to.params.id != from.params.id){
      //   this.recordId = to.params.id;
      //   this.id = to.params.id
      //   this.doFetchTotalComments({ id: to.params.id })
      //   this.doFind({ id: to.params.id });
      //   firebase
      //     .firestore()
      //     .collection("prescription")
      //     .doc(to.params.id)
      //     .collection("typing_comments")
      //     .onSnapshot(async (coll) => {
      //       const usersTyping = [];
      //       coll.docChanges().forEach((doc) => {
      //         const type = doc.type;
      //         if (type == "added") {
      //           usersTyping.push(doc.doc.data());
      //         }
      //       });
      //       let usersTypingFiltered = usersTyping.filter((I) => {
      //         if (I.userId != this.currentUser.id) {
      //           return I;
      //         }
      //       });
      //       if (usersTypingFiltered.length > 0) this.typing = true;
      //       else this.typing = false;
      //     });
      //     this.resetCommentsCursor();
      //     this.doFindComments({ id: to.params.id });
      //     this.setMountedRecord(true);
      // }
      // react to route changes...
    },
    lang(){
      document.title =
        this.i18n('app.title') +
        ' | ' +
        this.i18n('drugDeal.services.prescriptions') 
        
    },
    async record() {
      if (this.record) {
        // this.datePresentation = this.datePresenter(newvalue.createdAt);
        this.datePresentation = this.record.createdAt
        if (this.record.viewers) {
          if (!this.record.viewers.hasOwnProperty(this.currentUser.id)) {
            //   debugger
            this.record.viewers[this.currentUser.id] = true;
            this.record.createdAt = new Date(
              this.record.createdAt.seconds * 1000
            );
            this.record.updatedAt = new Date(
              this.record.updatedAt.seconds * 1000
            );
            delete this.record.id;
            this.doUpdate({ id: this.recordId, values: this.record });
          }
        } else {
          this.record.viewers = {};
          this.record.viewers[this.currentUser.id] = true;
          this.record.createdAt = new Date(
            this.record.createdAt.seconds * 1000
          );
          this.record.updatedAt = new Date(
            this.record.updatedAt.seconds * 1000
          );
          delete this.record.id;
          this.doUpdate({ id: this.recordId, values: this.record });
        }
      }
    },
    
    addedCommentorId(newval) {
      if (newval) {
        this.setCreatorToComment();
      }
    },
    comments(newval) {
      // debugger
      // if(newval.length == 0) this.rowsToBeDisplayed = []
      //
      if (newval.length > 0) {
        if(!this.newCommentCreated){
          this.setLoadMoreLast();
          this.setDisableNext({ id: this.$route.params.id });
        }        
        newval.forEach((record) => {
          var foundIndex = this.rowsToBeDisplayed.findIndex(
            (comment) => comment.id == record.id
          );
          if (foundIndex == -1) {
            this.rowsToBeDisplayed.push(record);
          } else {
            // let updatedComment = this.rowsToBeDisplayed.filter((comment) => comment.id == record.id);
            // console.log('updateComments henaa',updatedComment);
            Object.assign(this.rowsToBeDisplayed[foundIndex], record);
          }
        });
      }
    },
    comment(newVal, oldVal) {
      if (newVal && newVal !== "" && newVal !== oldVal) {
        firebase
          .firestore()
          .collection("prescription")
          .doc(this.id)
          .collection("typing_comments")
          .doc(this.currentUser.id)
          .set({ userId: this.currentUser.id });
        clearTimeout(this.timeOutVar);
        this.timeOutVar = setTimeout(() => {
          firebase
            .firestore()
            .collection("prescription")
            .doc(this.id)
            .collection("typing_comments")
            .doc(this.currentUser.id)
            .delete();
        }, 600);
      } else {
        firebase
          .firestore()
          .collection("prescription")
          .doc(this.id)
          .collection("typing_comments")
          .doc(this.currentUser.id)
          .delete();
      }
    },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    recordDetailsPresenter(name) {
      if (this.isRTL) {
        return name.ar;
      } else {
        return name.en;
      }
    },
    ...mapActions({
      doFind: "prescriptions/view/doFind",
      setHeaderBg: "layout/setHeaderBg",
      setNavbar: "layout/setNavbar",
      setFooter: "layout/setFooter",
      doCreateComment: "prescriptions/form/doCreateComment",
      doUpdate: "prescriptions/form/doUpdate",
      doUpdateComment: "prescriptions/form/doUpdateComment",
      doFindCommentsFirstBatch: "prescriptions/view/doFindCommentsFirstBatch",
      doFetchMoreComments: "prescriptions/view/doFetchMoreComments",
      setCreatorToComment: "prescriptions/view/setCreatorToComment",
      setMountedRecord: "prescriptions/view/setMountedRecord",
      setLoadMoreLast: "prescriptions/view/setLoadMoreLast",
      setDisableNext: "prescriptions/view/setDisableNext",
      resetCommentsCursor: "prescriptions/view/resetCommentsCursor",
      doFetchTotalComments: "prescriptions/view/doFetchTotalComments"
      //    doFetchNotification: 'notification/doFetch'
    }),
    close() {
      this.deletePrescription = false;
    },
    datePresenter(date) {
      const language = localStorage.getItem('language')
      // debugger
      return moment(new Date(date.seconds * 1000)).locale(language).format('DD-MM-yyyy hh:mm a')
    },
    datePresenterFromNow(date) {
      const language = localStorage.getItem('language')
      moment.locale(language);
      if(moment(date * 1000).fromNow() == 'a few seconds ago')
      {
        return 'Just Now'
      }        
      else{
        return moment(date * 1000).fromNow()
      }
    },
    closeComment() {
      this.deleteComment = false;
    },
    async onLoad(index, done) {
      setTimeout(() => {
        if (this.recordAfterLast.length > 0) {
          this.doFetchMoreComments({ id: this.$route.params.id })
          this.newCommentCreated = false
        //   this.doFindComments({ id: this.$route.params.id });
          
        }
        done();
      }, 2000);
    },
    viewAllComments() {
      // let length = this.getObjectLength(this.commentsObject)
      // let length = Object.keys(this.commentsObject).length;
      this.commentsLength = this.commentsLength + 2;
      let commentsLength = Object.keys(this.commentsObject).length;
      if (this.commentsLength == commentsLength) {
        this.allComments = false;
        this.allComments = false;
        this.commentsLength = this.comments.length;
      } else if (this.commentsLength > commentsLength) {
        this.allComments = false;
        this.commentsLength = this.comments.length;
      }
      this.slicedComment = this.comments.slice(0, commentsLength);
    },
    editComment(value) {
      this.editCommentId = value;
      // this.updatedComment
      const oldComment = this.rowsToBeDisplayed.find((comment) => comment.id == value) 
      // debugger
      this.updatedComment = oldComment.comment
    },
    getObjectLength(comments) {
      Object.size = function (obj) {
        var size = 0,
          key;
        for (key in obj) {
          if (obj.hasOwnProperty(key)) size++;
        }
        return size;
      };
      // const myObj = {}
      var size = Object.size(comments);
      return size;
    },
    offlineNotificationShow(message){
      this.$q.notify({
        message: message,
        position: "top-right",
        timeout: 3000,
      });
    },
    async doSubmitComment() {
      // console.log('Initially ' + (window.navigator.onLine ? 'on' : 'off') + 'line');
      // debugger
      const message = this.i18n('drugDeal.common.noInternetConnection')
      if(!window.navigator.onLine){
        this.offlineNotificationShow(message)
      }
      if(window.navigator.onLine){    
      this.newCommentCreated = true  
      var payload = {
        createdBy: this.currentUser.id,
        comment: this.comment,
        id: this.$route.params.id,
      };
      
      await this.doCreateComment(payload);
      
      this.comment = "";
      if (this.record && !this.record.subscribers) {
        let subscribers = {};
        subscribers[this.currentUser.id] = true;
        this.record.subscribers = subscribers;
        // this.record.createdAt = new Date().toString()
        this.record.createdAt = new Date(this.record.createdAt.seconds * 1000);
        this.record.updatedAt = new Date(this.record.updatedAt.seconds * 1000);
        let recordId = this.record.id;
        delete this.record.id;
        await this.doUpdate({ id: recordId, values: this.record });
      }
      // if(!this.record.subscribers){
      //     this.record.subscribers = {}
      //     this.record.subscribers[this.currentUser.id] = true
      //     await this.doUpdate({id:this.record.id,values:this.record})
      // }
      else if (
        this.record &&
        this.record.subscribers &&
        !this.record.subscribers.hasOwnProperty(this.currentUser.id)
      ) {
        this.record.subscribers[this.currentUser.id] = true;
        this.record.viewers[this.currentUser.id] = true;
        this.recordId = this.record.id;
        this.record.createdAt = new Date(this.record.createdAt.seconds * 1000);
        this.record.updatedAt = new Date(this.record.updatedAt.seconds * 1000);
        delete this.record.id;
        await this.doUpdate({ id: this.recordId, values: this.record });
      } 
      
      // payload['creator'] = this.currentUser
      // console.log(new Date());
      // debugger
      // this.rowsToBeDisplayed.push(payload)
    }
    },
    async updateComment(commentId, createdAt) {
      const payload = {
        createdBy: this.currentUser.id,
        comment: this.updatedComment,
        createdAt: new Date(createdAt.seconds * 1000),
      };
      const updatedComment = await this.doUpdateComment({
        prescId: this.$route.params.id,
        commentId,
        values: payload,
      });
      this.updatedComment = "";
      this.editCommentId = null;
      var foundIndex = this.rowsToBeDisplayed.findIndex(
        (el) => el.id == commentId
      );
      if (foundIndex != -1) {
        Object.assign(this.rowsToBeDisplayed[foundIndex], updatedComment);
      }
    },
    removeElement(commentId) {
      this.rowsToBeDisplayed = this.rowsToBeDisplayed.filter(
        (I) => I.id !== commentId
      );
    },
    doGoToPrescriptions() {
      this.$router.push("/prescriptions");
    },
    goToMyPrescriptions() {
      this.$router.push("/my-prescriptions");
    },
    async findRecord(id){
      this.recordId = id;
      this.id = id
      await this.doFetchTotalComments({ id })
      await this.doFind({ id });
        firebase
          .firestore()
          .collection("prescription")
          .doc(id)
          .collection("typing_comments")
          .onSnapshot(async (coll) => {
            const usersTyping = [];
            coll.docChanges().forEach((doc) => {
              const type = doc.type;
              if (type == "added") {
                usersTyping.push(doc.doc.data());
              }
            });
            let usersTypingFiltered = usersTyping.filter((I) => {
              if (I.userId != this.currentUser.id) {
                return I;
              }
            });
            if (usersTypingFiltered.length > 0) this.typing = true;
            else this.typing = false;
          });
          this.resetCommentsCursor();
          this.doFindCommentsFirstBatch({ id: id });
          this.setMountedRecord(true);
      
    }
  },
  components: {
    [DeleteEdit.name]: DeleteEdit,
  },
  computed: {
    isRTL() {
      return i18n.locale == "ar";
    },
    lang(){
      // debugger    
      return i18n.locale
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      saveLoading: "prescriptions/form/saveLoading",
      saveCommentLoading: "prescriptions/form/saveCommentLoading",
      record: "prescriptions/view/record",
      user: "prescriptions/view/user",
      comments: "prescriptions/view/comments",
      commentsTotal: "prescriptions/view/commentsTotal",
      addedCommentorId: "prescriptions/view/addedCommentorId",
      addedCommentId: "prescriptions/view/addedCommentId",
      recordAfterLast: "prescriptions/view/recordAfterLast",
      loadingComments: "prescriptions/view/loadingComments",
      is_screen_xs: "layout/is_screen_xs",
    }),
    totalLength() {
      if (!this.allComments) {
        return this.comments.length;
      } else {
        return this.commentsLength;
      }
    },
  },
  async mounted() {
    this.setNavbar("services");
    this.setHeaderBg("bg-shadow");
    this.setFooter(false);
    this.rowsToBeDisplayed = [];
    // await this.doFetchNotification(this.currentUser.id)
  },
  async created() {
    document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('drugDeal.services.prescriptions') 
    await this.findRecord(this.$route.params.id)
    // this.recordId = this.$route.params.id;
    // this.doFetchTotalComments({ id: this.$route.params.id })
    // await this.doFind({ id: this.$route.params.id });
    // await firebase
    //   .firestore()
    //   .collection("prescription")
    //   .doc(this.id)
    //   .collection("typing_comments")
    //   .onSnapshot(async (coll) => {
    //     const usersTyping = [];
    //     coll.docChanges().forEach((doc) => {
    //       const type = doc.type;
    //       if (type == "added") {
    //         usersTyping.push(doc.doc.data());
    //       }
    //     });
    //     let usersTypingFiltered = usersTyping.filter((I) => {
    //       if (I.userId != this.currentUser.id) {
    //         return I;
    //       }
    //     });
    //     if (usersTypingFiltered.length > 0) this.typing = true;
    //     else this.typing = false;
    //   });
    // this.resetCommentsCursor();
    // await this.doFindComments({ id: this.$route.params.id });
    // this.setMountedRecord(true);
  },
};
